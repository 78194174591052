/**
 * This object hold all event types that can happen during an authentication process\
 * It is used to subscribe to an event through setAuthConfig method
 *
 * @see setAuthConfig for more details how to subscribe for event
 */
export const AUTH_EVENTS_TYPES = {
  REGISTER_REQUEST: 'onRegisterRequest',
  REGISTER: 'onRegister',
  LOGIN_REQUEST: 'onLoginRequest',
  LOGIN: 'onLogin',
  LOGIN_WITH_PASSWORD: 'onLoginWithPassword',
  LOGOUT_REQUEST: 'onLogoutRequest',
  LOGOUT: 'onLogout',
  UPDATE_PASSWORD_REQUEST: 'onUpdatePasswordRequest',
  FORGOT_PASSWORD_REQUEST: 'onForgotPasswordRequest',
  REFRESH_TOKEN: 'onRefreshToken',
  RENEW_SESSION: 'onSessionRenew',
  SET_TOKEN: 'onSetToken',
} as const;

export const CONFIG_IDENTITY_OPTIONS = ['domain', 'clientId', 'redirectUri', 'postLogoutRedirectUri', 'scope'] as const;

/**
 * This object hold all user roles which are used inside oneecosystem applications
 */
export const USER_ROLES = {
  // General roles
  CUSTOMER: 'Customer',
  ADMINISTRATOR: 'Administrator',
  IDENTITY_ADMIN: 'Identityadmin',
  // Life roles
  SUPPORT: 'Support',
  TEAM_LEADER: 'TeamLeader',
  KYC_OPERATOR: 'KycOperator',
  // Dealshaker roles
  DS_ADMIN: 'DS-admin',
  DS_REGIONAL_DIRECTOR: 'DS-regional-director',
  DS_COUNTRY_MANAGER: 'DS-country-manager',
  DS_AREA_MANAGER: 'DS-area-manager',
} as const;
